<template>
  <div class="account-fortune-group">
    <div
      :class="[
        'fortune-item',
        fortuneIndex === index ? 'fortune-item-active' : '',
      ]"
      v-for="(item, index) in fortuneLang"
      :key="index"
      @click="handleClick(item, index)"
    >
      <img :src="item.imgUrl" alt="" />
      <div class="info">
        <span class="info-tit">{{ item.title }}</span>
        <span class="info-money">{{ item.money }}</span>
      </div>
      <div
        :class="[
          'operate-btn',
          {
            'operate-btn-withdraw': item.button.title == '提现',
            'operate-btn-cash-exchange': item.button.title == '兑现',
          },
        ]"
        v-if="item.button"
        @click.stop="selectOperate(item.button.title)"
      >
        {{ item.button.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountFortuneGroup",
  props: {
    fortunes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      //资产列表
      fortuneLang: [
        {
          title: "账户余额",
          imgUrl: require("assets/images/account-records/account-records-cash.png"),
          money: 0,
          type: 1,
          button: {
            title: "提现",
          },
        },
        {
          title: "字符包",
          imgUrl: require("assets/images/account-records/account-records-char.png"),
          money: 0,
          type: 2,
          button: {
            title: "兑现",
          },
        },
        {
          title: "版权收益",
          imgUrl: require("assets/images/account-records/account-records-copyright-income.png"),
          money: 0,
          type: 6,
        },
        {
          title: "版权实务",
          imgUrl: require("assets/images/account-records/account-records-practice.png"),
          money: 0,
          type: 7,
        },
      ],
      //选择的资产按钮
      fortuneIndex: 0,
    };
  },
  methods: {
    //点击资产按钮
    handleClick(item, index) {
      this.fortuneIndex = index;
      this.$emit("handleFortune", item.type);
    },
    //选择操作按钮
    selectOperate(title) {
      switch (title) {
        case "提现":
          return this.withdraw();
        case "兑现":
          return this.cashExchange();
      }
    },
    //点击提现
    withdraw() {
      this.$emit("onWithdraw");
    },
    //点击兑现
    cashExchange() {
      this.$emit("onCashExchange");
    },
  },
  watch: {
    fortunes: {
      deep: true,
      handler(arr) {
        this.fortuneLang.forEach((i, x) => {
          if (arr[x]) {
            this.$set(this.fortuneLang[x], "money", arr[x].num);
          }
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.account-fortune-group {
  margin-top: 20px;
  .fortune-item {
    &:first-child ~ .fortune-item {
      margin-top: 10px;
    }
    &.fortune-item-active {
      border: 1px solid #ff6900;
    }
    &:hover {
      border: 1px solid #ff6900;
    }
    @include noselect;
    position: relative;
    display: flex;
    height: 78px;
    padding: 16px 14px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    > img {
      width: 45px;
      height: 46px;
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      &-tit {
        margin-top: 6px;
        font-size: 12px;
        font-weight: 500;
        color: #666666;
      }
      &-money {
        margin-top: 9px;
        font-weight: bold;
        color: #333333;
      }
    }
    .operate-btn {
      position: absolute;
      right: 9px;
      top: 9px;
      width: 42px;
      height: 22px;
      border-radius: 11px;
      font-size: 12px;
      font-weight: bold;
      text-align: center;
      line-height: 22px;
      &-withdraw {
        background-color: #e6efff;
        color: #ff6900;
      }
      &-cash-exchange {
        background-color: #ffedef;
        color: #ff808b;
      }
    }
  }
}
</style>
