import { render, staticRenderFns } from "./AccountRecordsDialog.vue?vue&type=template&id=6b202dc9&scoped=true&"
import script from "./AccountRecordsDialog.vue?vue&type=script&lang=js&"
export * from "./AccountRecordsDialog.vue?vue&type=script&lang=js&"
import style0 from "./AccountRecordsDialog.vue?vue&type=style&index=0&id=6b202dc9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b202dc9",
  null
  
)

export default component.exports